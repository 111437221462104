<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['greasySkin'])">
      <single-answer-question
        title="label.greasySkin"
        :value="greasySkin"
        :options="$options.amountOptions"
        dysfunction="skin-oiliness"
        @input="onFieldChange('greasySkin', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasGreasySkin"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['openFacialPores'])"
      >
        <single-answer-question
          id="open-facial-pores-question"
          title="label.openFacialPores"
          :value="openFacialPores"
          :options="$options.amountOptions"
          dysfunction="skin-oiliness"
          @input="onFieldChange('openFacialPores', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasGreasySkin"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['excessShine'])"
      >
        <single-answer-question
          id="excess-shine-question"
          title="label.excessShine"
          :value="excessShine"
          :options="$options.yesNoResponseOptions"
          dysfunction="skin-oiliness"
          yes-no
          @input="onFieldChange('excessShine', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  YES_NO_RESPONSE_OPTIONS,
  AMOUNT_OPTIONS
} from '@/modules/questionnaire/constants/questions';

export default {
  name: 'OilinessTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  amountOptions: AMOUNT_OPTIONS,
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    hasGreasySkin: {
      type: Boolean,
      default: false
    },
    greasySkin: {
      type: String,
      default: ''
    },
    openFacialPores: {
      type: String,
      default: ''
    },
    excessShine: {
      type: String,
      default: ''
    }
  }
};
</script>
