<template>
  <oiliness-template
    :has-greasy-skin="hasGreasySkin"
    :greasy-skin="greasySkin"
    :open-facial-pores="openFacialPores"
    :excess-shine="excessShine"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import OilinessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/oiliness/OilinessTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const requiredFieldsToReset = ['openFacialPores', 'excessShine'];

const fieldsToReset = ['greasySkinLocations', 'onlyGreasyInSummer', 'greasySkinDiscomfort'];

const FIELDS = [
  ...fieldsToReset.map(field),
  ...requiredFieldsToReset.map(requiredField),
  requiredField('greasySkin')
];

export default {
  name: 'Oiliness',
  components: {
    OilinessTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    hasGreasySkin() {
      return yes(this.greasySkin);
    }
  },
  watch: {
    greasySkin(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);

        this.showNextStep();
      } else if (this.hasGreasySkin) {
        this.scrollTo('#open-facial-pores-question');
      }
    },
    openFacialPores(newValue, oldValue) {
      if (!oldValue && this.excessShine) {
        this.showNextStep();
      } else {
        this.scrollTo('#excess-shine-question');
      }
    },
    excessShine(newValue, oldValue) {
      if (!oldValue && this.openFacialPores) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'openFacialPores' || fieldName === 'excessShine') {
        return this.hasGreasySkin;
      }

      return true;
    }
  }
};
</script>
